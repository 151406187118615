'use client';

import { AuthCard, AuthBackground } from '@/components/Auth';
import LogoFull from "@/components/ui/Logo/Logo";
import Wrapper from '@/components/ui/Wrapper';
export default function SignInPage() {
  return <Wrapper className="flex items-center justify-center min-h-screen mx-auto my-8" data-sentry-element="Wrapper" data-sentry-component="SignInPage" data-sentry-source-file="page.jsx">
      <div className="flex flex-col items-center w-full gap-8 mt-[-5%]">
        <LogoFull className="hidden lg:block w-[180px]" data-sentry-element="LogoFull" data-sentry-source-file="page.jsx" />

        <div className="flex items-center justify-center w-full overflow-hidden lg:border lg:rounded-xl">
          <div className="relative flex items-center self-stretch justify-center w-full px-4 py-12 lg:w-1/2 sm:px-6 lg:px-16">
            <AuthCard className="z-10" data-sentry-element="AuthCard" data-sentry-source-file="page.jsx" />
          </div>

          <div className="hidden h-full bg-center bg-cover lg:block lg:w-1/2 max-h-[70vh] self-stretch">
            <AuthBackground data-sentry-element="AuthBackground" data-sentry-source-file="page.jsx" />
          </div>
        </div>
      </div>
    </Wrapper>;
}